import React, { Component, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';


class Agency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            firstName: '',
            lastName: '',
            phone: '',
            agencyName: '',
            state: '',
            address: '',
            city: '',
            email: '',
            zip: '',
            password: '',
            users: [],
            agencies: [],
            showActive: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInactivate = this.handleInactivate.bind(this);
        this.handleActivate = this.handleActivate.bind(this);
        this.handleActiveClick = this.handleActiveClick.bind(this);
    }
    componentDidMount = async () => {
        var agencyId = localStorage.getItem('agencyId');
        const userId = localStorage.getItem('userId');
        if(userId == "" || !userId)
        {
            this.props.history.push('/home');
        }else{
            const userResponse = await fetch('https://api.leadmanagertraining.com/api/ezllusers', { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const users = await userResponse.json();
            this.setState({users: users});

            const agencyResponse = await fetch('https://api.leadmanagertraining.com/api/ezllagency', { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const agencies = await agencyResponse.json();
            this.setState({agencies: agencies});
        }
    }
    componentDidUpdate(prevProps){
       
    }
    handleSubmit = async () => {
        const agency = {
            name: this.state.agencyName,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            userName: this.state.userName,
            password: this.state.password,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phone: this.state.phone, 
            email: this.state.email
        };
        try {
            await  fetch('https://api.leadmanagertraining.com/api/ezllagency', { 
                method: 'POST', 
                body: JSON.stringify(agency),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        }
        catch(e) {
            console.log('Submit Failed', e);
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleStateChange = (e, record) => {
        console.log(e.target.value);
        // this.setState({
        //     state: e.target.value
        // });
        const request = {
            agencyId: e.target.value,
            userName: record.userName,
            password: record.password,
            firstName: record.firstName,
            lastName: record.lastName,
            phone: record.phone, 
            email: record.email,
            status: record.status,
            id: record.id
        };
        try {
            this.setState({ loading: true }, async () => {
                const response = await  fetch('https://api.leadmanagertraining.com/api/ezllusers/UpdateEZLLUser', { 
                    method: 'POST', 
                    body: JSON.stringify(request),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const user = await response.json();
                if(user.id == 0){
                    this.setState({loading: false});
                    //this.setState({invalid: true});
                }
                else{
                    this.setState({
                        loading: false, 
                        submitted: true,
                        firstName: '',
                        lastName: '',
                        userName: '',
                        password: '',
                        phone: '',
                        email: '',
                    });
                    const userResponse = await fetch('https://api.leadmanagertraining.com/api/ezllusers', { 
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const users = await userResponse.json();
                    this.setState({users: users});
                }
            });
        }
        catch(e) {
            console.log('Submit Failed', e);
        }
    }
    handleInactivate = (record) => {
        console.log(record);
        const request = {
            agencyId: record.agencyId,
            userName: record.userName,
            password: record.password,
            firstName: record.firstName,
            lastName: record.lastName,
            phone: record.phone, 
            email: record.email,
            status: 4,
            id: record.id
        };
        try {
            this.setState({ loading: true }, async () => {
                const response = await  fetch('https://api.leadmanagertraining.com/api/ezllusers/UpdateEZLLUser', { 
                    method: 'POST', 
                    body: JSON.stringify(request),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const user = await response.json();
                if(user.id == 0){
                    this.setState({loading: false});
                    //this.setState({invalid: true});
                }
                else{
                    this.setState({
                        loading: false, 
                        submitted: true,
                        firstName: '',
                        lastName: '',
                        userName: '',
                        password: '',
                        phone: '',
                        email: '',
                    });
                    const userResponse = await fetch('https://api.leadmanagertraining.com/api/ezllusers', { 
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const users = await userResponse.json();
                    this.setState({users: users});
                }
            });
        }
        catch(e) {
            console.log('Submit Failed', e);
        }
    }
    handleActivate = (record) => {
        console.log(record);
        const request = {
            agencyId: record.agencyId,
            userName: record.userName,
            password: record.password,
            firstName: record.firstName,
            lastName: record.lastName,
            phone: record.phone, 
            email: record.email,
            status: 0,
            id: record.id
        };
        try {
            this.setState({ loading: true }, async () => {
                const response = await  fetch('https://api.leadmanagertraining.com/api/ezllusers/UpdateEZLLUser', { 
                    method: 'POST', 
                    body: JSON.stringify(request),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const user = await response.json();
                if(user.id == 0){
                    this.setState({loading: false});
                    //this.setState({invalid: true});
                }
                else{
                    this.setState({
                        loading: false, 
                        submitted: true,
                        firstName: '',
                        lastName: '',
                        userName: '',
                        password: '',
                        phone: '',
                        email: '',
                    });
                    const userResponse = await fetch('https://api.leadmanagertraining.com/api/ezllusers', { 
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const users = await userResponse.json();
                    this.setState({users: users});
                }
            });
        }
        catch(e) {
            console.log('Submit Failed', e);
        }
    }
    handleModalClose = () => {
        this.setState({show: false});
    }
    handleModalShow = () => {
        this.setState({show: true});
    }
    handleActiveClick = () => {
        this.setState({showActive: !this.state.showActive});
    }
    render() {  
        const stateList = require('../data/states.json');
        var filteredUsers = [];
        var agencyId = localStorage.getItem('agencyId');
        var status = localStorage.getItem('status');
        if(status == 3){
            filteredUsers = this.state.users.filter(us => this.state.showActive ? us.status == 0 || us.status == 3 : us.status == 4);
        }else if(status == 2){
            filteredUsers = this.state.users.filter(us => this.state.showActive ? us.status == 1 && us.agencyId == agencyId : us.status == 4 && us.agencyId == agencyId);
        }

            
        return (
            <div style={{marginLeft: 10, marginTop: 10, width: '90%'}}>
                <Card>
                    <Card.Header>
                        USERS
                        <Button size="sm" variant="primary" style={{position: 'absolute', right: 0, marginRight: 10}} onClick={() => this.handleActiveClick()}>
                            {this.state.showActive ? "Show Inactive" : "Show Active"}
                        </Button>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Username</th>
                                            <th>Password</th>
                                            <th>Agency</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            filteredUsers.map(u => 
                                                <tr>
                                                    <td>
                                                        {u.firstName}
                                                    </td>
                                                    <td>
                                                        {u.lastName}
                                                    </td>
                                                    <td>
                                                        {u.email}
                                                    </td>
                                                    <td>
                                                        {u.phone}
                                                    </td>
                                                    <td>
                                                        {u.userName}
                                                    </td>
                                                    <td>
                                                        {u.password}
                                                    </td>
                                                    <td>
                                                        {
                                                            status == 3 ?
                                                            <select className="form-control" onChange={(e) => this.handleStateChange(e, u)} value={u.agencyId}>
                                                                <option value='0'></option>
                                                                {
                                                                    this.state.agencies.filter(i => i.inactive == false).map(a => 
                                                                        <option value={a.id}>{a.name}</option>
                                                                    )
                                                                }
                                                            </select>
                                                            :
                                                            <select className="form-control" onChange={(e) => this.handleStateChange(e, u)} value={u.agencyId} disabled>
                                                                <option value='0'></option>
                                                                {
                                                                    this.state.agencies.filter(i => i.inactive == false).map(a => 
                                                                        <option value={a.id}>{a.name}</option>
                                                                    )
                                                                }
                                                            </select>
                                                        }        
                                                    </td>
                                                    <td>
                                                        {
                                                            u.status != 4 ?
                                                                <Button size="sm" variant="secondary" onClick={(e) => this.handleInactivate(u)}>
                                                                    Inactivate
                                                                </Button>
                                                            :
                                                                <Button size="sm" variant="secondary" onClick={(e) => this.handleActivate(u)}>
                                                                    Activate
                                                                </Button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}
export default Agency;