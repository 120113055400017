import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import ModalHeader from 'react-bootstrap/ModalHeader'


class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            register: 0,
            password: '',
            userName: '',
            loading: false,
            register: 0,
            invalid: false,
            amount: 0,
            forgotPassword: false,
            email: '',
            forgotPasswordResult: '',
            show: false,
            user: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
    }
    componentDidMount(){
        localStorage.setItem('userId', '');
        localStorage.setItem('agencyId', '');
        localStorage.setItem('status', '');
    }
    componentDidUpdate(prevState){
        
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = async () => {
        const agency = {
            userName: this.state.userName,
            password: this.state.password,
        };
        try {
            this.setState({ loading: true }, async () => {
                const response = await fetch('https://api.leadmanagertraining.com/api/ezllusers/GetEZLLUser', { 
                    method: 'POST', 
                    body: JSON.stringify(agency),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const user = await response.json();
                if(user.id == 0){
                    this.setState({loading: false});
                    this.setState({invalid: true});
                }
                else{
                    this.setState({loading: false});
                    localStorage.setItem('userId', user.id);
                    localStorage.setItem('agencyId', user.agencyId);
                    localStorage.setItem('status', user.status);
                    if(user.status != 4){
                        this.props.history.push('/chat');
                    }
                    else{
                        this.setState({loading: false});
                        this.setState({invalid: true});
                    }
                }
            });
        }
        catch(e) {
            console.log('Submit Failed', e);
        }
    }
    handleForgotPassword(event) {
        this.setState({loading: true});
        const email = this.state.email;
        this.props.forgotPassword(email);
        this.setState({loading: false});
    }
    handleModalShow = () => {
        this.setState({show: true});
    }
    handleModalClose = () => {
        this.setState({show: false});
    }
    render() {
        return (
            <div style={{marginLeft: 10, marginTop: 10, width: '60%'}}>
                <Row>
                    <Col>
                        <Card border="success">
                            <Card.Header>LOGIN</Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col sm={8}>
                                        <Form.Group style={{paddingBottom: 10}}>
                                            <Form.Control size="lg" placeholder="User Name" name="userName" onChange={e => this.handleChange(e)} />
                                        </Form.Group>
                                        <Form.Group>
                                            {/* <Form.Label htmlFor="password">Password</Form.Label> */}
                                            <Form.Control type='Password' size="lg" placeholder="Password" name="password" onChange={e => this.handleChange(e)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                
                            </Card.Body>
                            <Card.Footer>
                                <Row>
                                    <Col>
                                        <Button className="rounded-pill btn-secondary" style={{color: '#000', backgroundColor: '#f1f8e9'}} onClick={e => this.handleSubmit(e)} disabled={this.state.loading}> {this.state.loading == true ? 'Please Wait' : 'Submit'}</Button>
                                        <Spinner animation="border" style={{display:this.state.loading == true ? 'block' : 'none'}} />
                                    </Col>
                                    <Col>
                                        <Button variant="link" style={{position: 'absolute', right: 0}} onClick={() => this.handleModalShow()}>Forgot Password</Button>
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: 10}}>
                                    <Col>
                                    {
                                        (this.state.invalid == true) &&
                                            <Alert variant="danger" id="invalid">Invalid User Name or Password.  Please Try Again.</Alert>
                                    }
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <Modal show={this.state.show} onHide={() => this.handleModalClose()}>
                    <Modal.Header closeButton="false">
                        <Modal.Title>Forgot Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            {/* <Form.Label htmlFor="userName">User Name</Form.Label> */}
                            <Form.Control size="lg" placeholder="Email" name="email" onChange={e => this.handleChange(e)} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={(e) => this.handleForgotPassword(e)}>
                            Submit
                        </Button>
                        {(this.props.forgotPasswordResult != '') &&
                            <Alert variant="info" id="invalid">{this.props.forgotPasswordResult}</Alert>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default Login;