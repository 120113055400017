import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';

import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';

const Chat = () => {
    const [ connection, setConnection ] = useState(null);
    const [ chat, setChat ] = useState([]);
    const [ agencyId, setAgencyId ] = useState(localStorage.getItem('agencyId'));
    const latestChat = useRef(null);
    const history = useHistory();
    const [ users, setUsers ] = useState([]);

    latestChat.current = chat;

    useEffect(async () => {
        const userId = localStorage.getItem('userId');
        if(userId == "" || !userId)
        {
            history.push('/home');
        }else{
            const newConnection = new HubConnectionBuilder()
                .withUrl('https://api.leadmanagertraining.com/hubs/chat', {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets 
                })
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);
        }

        fetch('https://api.leadmanagertraining.com/api/ezllusers', {
            "method": "GET"
        })
        .then(response => response.json())
        .then(response => {
            //console.log(response);
            setUsers(response);
        })
        .catch(err => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
            if (connection) {
                connection.start()
                .then(function () {
                    connection.on('ReceiveMessage', message => {
                        const updatedChat = [...latestChat.current];
                        updatedChat.push(message);
                        //updatedChat.reverse();
                        updatedChat.sort(function(a,b){
                            // Turn your strings into dates, and then subtract them
                            // to get a value that is either negative, positive, or zero.
                            return new Date(b.dts) - new Date(a.dts);
                        });
                        // const audio = new Audio('piece-of-cake-611.mp3');
                        // audio.play();
                        setChat(updatedChat);
                    });
                    connection.invoke('GetConnectionId')
                    .then(function (connectionId) {
                        console.log('Connected - ' + connectionId);
                            try {
                                var agencyId = localStorage.getItem('agencyId');
                                fetch('https://api.leadmanagertraining.com/api/chat/joingroup?agencyId=' + agencyId + '&connectionId=' + connectionId, { 
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                });
                            }
                            catch(e) {
                                console.log('Sending message failed.', e);
                            }
                    });

                })
                .catch(e => console.log('Connection failed: ', e));
            }
        
    }, [connection]);
    const sendMessage = async (user, message) => {
        const chatMessage = {
            user: user,
            message: message,
            agencyId: agencyId,
            userId: localStorage.getItem('userId'),
            dts: moment().format()
        };
        try {
            await  fetch('https://api.leadmanagertraining.com/api/chat/messages', { 
                method: 'POST', 
                body: JSON.stringify(chatMessage),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        }
        catch(e) {
            console.log('Sending message failed.', e);
        }
    }
    const handleAgencyChange = (agencyId) => {
        setAgencyId(agencyId);
        connection.invoke('GetConnectionId')
            .then(function (connectionId) {
                console.log('Connected - ' + connectionId);
                    try {
                        fetch('https://api.leadmanagertraining.com/api/chat/joingroup?agencyId=' + agencyId + '&connectionId=' + connectionId, { 
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    }
                    catch(e) {
                        console.log('Sending message failed.', e);
                    }
        });
        //setUsers({users: users.filter(u => u.agencyId == agencyId)});
        // fetch('https://api.leadmanagertraining.com/api/ezllusers', {
        //     "method": "GET"
        // })
        // .then(response => response.json())
        // .then(response => {
        //     setUsers({users: users});
        // })
        // .catch(err => {
        //     console.log(err);
        // });
    }
    return (
        
        <div>
            <ChatInput sendMessage={sendMessage} onAgencyChange={handleAgencyChange} />
            <hr />
            <ChatWindow chat={chat} agencyId={agencyId} users={users.filter(u => u.agencyId == agencyId)} connection={connection} />
        </div>
    );
};

export default Chat;