import React, { Component, createRef } from 'react';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { BsFillTrashFill  } from 'react-icons/bs';
import moment from 'moment';

class Upload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            agencies: [],
            file: '',
            selectedAgency: '',
            fileLink: '',
            agencyFiles: []
        };
        this.inputFile = createRef();
        this.handleAgencyChange = this.handleAgencyChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }
    componentDidMount = async () => {
        var agencyId = localStorage.getItem('agencyId');
        const userId = localStorage.getItem('userId');
        if(userId == "149")
        {
            const agenctResp = await fetch('https://api.leadmanagertraining.com/api/ezllagency', { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const agencies = await agenctResp.json();
            this.setState({agencies: agencies});
            
        }else{
            this.props.history.push('/home');
        }
    }
    handleAgencyChange = async (e) => {
        this.setState({selectedAgency: e.target.value});
        const agenctResp = await fetch('https://api.leadmanagertraining.com/api/ezllagency/GetAgencyFiles?agencyId=' + e.target.value, { 
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const agencyFiles = await agenctResp.json();
        this.setState({agencyFiles: agencyFiles});
    }
    handleFileChange = async (e) => {
        console.log(e.target.files[0]);
        this.setState({file: e.target.files[0]});
    }
    handleUpload = async (e) => {
        var agencyId = localStorage.getItem('agencyId');
        const data = new FormData();
        if(this.state.file != null){
            data.append('file', this.state.file);
        }
        const url = 'https://api.leadmanagertraining.com/api/ezllagency/uploadfile?agencyId=' + this.state.selectedAgency;
        const response = await fetch(url, {
            method: "POST",
            body: data
        });
        const fileLink = await response.json();
        this.setState({fileLink: fileLink, file: '', agencyFiles: fileLink});
        console.log(this.inputFile);
        this.inputFile.current.value = "";
    }
    handleDelete = async (e) => {
        const url = 'https://api.leadmanagertraining.com/api/ezllagency/deletefile?agencyId=' + this.state.selectedAgency + '&fileName=' + e.fileName;
        const response = await fetch(url, {
            method: "POST",
        });
        const fileLink = await response.json();
        this.setState({agencyFiles: fileLink});
    }
    render() {
        return (
            <div className="container" style={{marginTop: 10}}>
                <Card>
                    <Card.Header>
                        UPLOAD
                    </Card.Header>
                    <Card.Body>
                        <Row style={{paddingTop: 10, paddingBottom: 10}}>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Choose Agency To Upload To</Form.Label>
                                    <Form.Control as="select" onChange={this.handleAgencyChange} className="form-select">
                                        <option value=''></option>
                                        {this.state.agencies.filter(i => i.inactive == false).map(a => 
                                            <option key={a.id} value={a.id}>{a.name}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>   
                            <Col md={6}>
                                <Form.Control size="lg" type="file" onChange={this.handleFileChange} ref={this.inputFile} />
                                {/* <input type="file" onChange={this.handleFileChange} ref={this.inputFile} /> */}
                            </Col>
                        </Row>
                        <Row style={{marginTop: 10}}>   
                            <Col md={6}>
                                <Button className="rounded-pill btn-secondary" style={{color: '#000', backgroundColor: '#f1f8e9'}} onClick={e => this.handleUpload(e)}>Upload</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card style={{marginTop: 20}}>
                    <Card.Header>
                        PAST FILES
                    </Card.Header>
                    <Card.Body>
                        <Row style={{paddingTop: 10, paddingBottom: 10}}>
                            <Col md={12}>
                                <table style={{width: '50%', border: 'solid 1px #ccc'}}>
                                    <thead style={{borderBottom: 'solid 1px #ccc', fontWeight: 'bold'}}>
                                        <tr>
                                            <td style={{borderRight: 'solid 1px #ccc'}}>File</td>
                                            <td style={{borderRight: 'solid 1px #ccc'}}>Upload Date</td>
                                            <td></td>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.agencyFiles.map(f =>
                                            <tr key={f.fileName} style={{borderBottom: 'solid 1px #ccc'}}>
                                                <td style={{borderRight: 'solid 1px #ccc'}}><a href={f.link}>{f.fileName}</a></td>
                                                <td style={{borderRight: 'solid 1px #ccc'}}>{moment(f.uploadDate).format('MM/DD/YYYY')}</td>
                                                <td style={{textAlign: 'center'}}><Button variant="link" onClick={() => this.handleDelete(f)}><BsFillTrashFill /></Button></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}
export default Upload;