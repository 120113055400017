import React, { Component, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';
import {BsChevronRight} from 'react-icons/bs';


class Agency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            firstName: '',
            lastName: '',
            phone: '',
            agencyName: '',
            state: '',
            address: '',
            city: '',
            email: '',
            zip: '',
            password: '',
            agencies: [],
            isEdit: false,
            agencyId: 0,
            userNameInUse: false,
            partnerId: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInactivate = this.handleInactivate.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }
    componentDidMount = async () => {
        const userId = localStorage.getItem('userId');
        if(userId == "" || !userId)
        {
            this.props.history.push('/home');
        }else{
            const agencyResponse = await fetch('https://api.leadmanagertraining.com/api/ezllagency', { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const agencies = await agencyResponse.json();
            this.setState({agencies: agencies});
        }
        this.handleAgencyClick = this.handleAgencyClick.bind(this);
    }
    componentDidUpdate(prevProps){
       
    }
    handleSubmit = async () => {
        const request = {
            name: this.state.agencyName,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            userName: this.state.userName,
            password: this.state.password,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phone: this.state.phone, 
            email: this.state.email,
            partnerId: this.state.partnerId
        };
        try {
            if(!this.state.isEdit){
                const response = await fetch('https://api.leadmanagertraining.com/api/ezllagency', { 
                    method: 'POST', 
                    body: JSON.stringify(request),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const agency = await response.json();
                    if(agency.id == 0){
                        this.setState({loading: false});
                    }
                    else{
                        this.setState({
                            loading: false, 
                            submitted: true,
                            userName: '',
                            firstName: '',
                            lastName: '',
                            phone: '',
                            agencyName: '',
                            state: '',
                            address: '',
                            city: '',
                            email: '',
                            zip: '',
                            password: '',
                            partnerId: '',
                            isEdit: false
                        });
                        
                    }
            } else {
                request.id = this.state.agencyId;
                const response = await fetch('https://api.leadmanagertraining.com/api/ezllagency/UpdateEZLLAgency', { 
                    method: 'POST', 
                    body: JSON.stringify(request),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const agencies = await response.json();
                this.setState({agencies: agencies});
                
                this.setState({
                    loading: false, 
                    submitted: true,
                    userName: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    agencyName: '',
                    state: '',
                    address: '',
                    city: '',
                    email: '',
                    zip: '',
                    password: '',
                    agencyId: 0,
                    isEdit: false,
                    partnerId: ''
                });
            }
        }
        catch(e) {
            console.log('Submit Failed', e);
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleStateChange = (e) => {
        this.setState({
            state: e.target.value
        });
    }
    handleModalClose = () => {
        this.setState({show: false});
    }
    handleModalShow = () => {
        this.setState({show: true});
    }
    handleAgencyClick = async (agency) => {
        const userResponse = await fetch('https://api.leadmanagertraining.com/api/ezllusers', { 
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const users = await userResponse.json();
        const user = users.filter(u => u.agencyId == agency.id && u.status == 2);
        if(user != null){
            this.setState({
                userName: user[0].userName,
                firstName: user[0].firstName,
                lastName: user[0].lastName,
                phone: user[0].phone,
                agencyName: agency.name,
                state: agency.state,
                address: agency.address,
                city: agency.city,
                email: user[0].email,
                zip: agency.zip,
                password: user[0].password,
                isEdit: true,
                agencyId: agency.id,
                partnerId: agency.partnerId ? agency.partnerId : ''
            });
        }
    }
    handleInactivate = async () => {
        const request = {
            id: this.state.agencyId,
            name: this.state.agencyName,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            inactive: true
        };
        try {
            const response = await fetch('https://api.leadmanagertraining.com/api/ezllagency/UpdateEZLLAgency', { 
                method: 'POST', 
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const agencies = await response.json();
            this.setState({agencies: agencies});
            
            this.setState({
                loading: false, 
                submitted: true,
                userName: '',
                firstName: '',
                lastName: '',
                phone: '',
                agencyName: '',
                state: '',
                address: '',
                city: '',
                email: '',
                zip: '',
                password: '',
                agencyId: 0,
                isEdit: false,
                partnerId: ''
            });
            
        }
        catch(e) {
            console.log('Submit Failed', e);
        }
    }
    handleBlur = async () => {
        const userResponse = await fetch('https://api.leadmanagertraining.com/api/ezllusers', { 
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const users = await userResponse.json();
        const user = users.filter(u => u.userName == this.state.userName);
        console.log(user);
        const userNameInUse = user.length > 0 ? true : false;
        this.setState({userNameInUse: userNameInUse});
    }
    render() {  
        const stateList = require('../data/states.json');
        
        return (
            <Row style={{marginLeft: 10, marginTop: 10, width: '80%'}}>
                <Col>
                    <ListGroup>
                        {this.state.agencies.filter(i => i.inactive == false).map(a =>
                            <ListGroup.Item className="d-flex justify-content-between align-items-start" action onClick={() => this.handleAgencyClick(a)}>
                                {a.name}
                                <BsChevronRight />
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>REGISTER AGENCY</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={10}>
                                    <Form.Group>
                                        <Form.Label htmlFor="agencyName">Agency Name</Form.Label>
                                        <Form.Control size="lg" name="agencyName" onChange={e => this.handleChange(e)} value={this.state.agencyName} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor="partnerId">Partner ID</Form.Label>
                                        <Form.Control size="lg" name="partnerId" onChange={e => this.handleChange(e)} value={this.state.partnerId} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor="address">Address</Form.Label>
                                        <Form.Control size="lg" name="address" onChange={e => this.handleChange(e)} value={this.state.address} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor="city">City</Form.Label>
                                        <Form.Control size="lg" name="city" onChange={e => this.handleChange(e)} value={this.state.city} />
                                    </Form.Group>
                                    <Form.Group>
                                        <label htmlFor="spend">State</label><br />
                                        <Form.Control as="select" size="lg"
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            name="ineligible"
                                            value={this.state.state}
                                            onChange={(e) => this.handleStateChange(e)}>
                                            {
                                                stateList.map(d => 
                                                    <option key={d.abbreviation}>{d.name}</option>
                                                )
                                            }
                                        </Form.Control>  
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor="zip">Zip Code</Form.Label>
                                        <Form.Control size="lg" name="zip" onChange={e => this.handleChange(e)} value={this.state.zip} />
                                    </Form.Group>
                                    <div style={{paddingTop: 10}}>
                                        <Card border="success">
                                            <Card.Header>ADMIN USER INFORMATION</Card.Header>
                                            <Card.Body>
                                                <Form.Group>
                                                    <Form.Label htmlFor="firstName">First Name</Form.Label>
                                                    <Form.Control size="lg" name="firstName" onChange={e => this.handleChange(e)} value={this.state.firstName} />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label htmlFor="lastName">Last Name</Form.Label>
                                                    <Form.Control size="lg" name="lastName" onChange={e => this.handleChange(e)} value={this.state.lastName} />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label htmlFor="phone">Phone</Form.Label>
                                                    <Form.Control size="lg" name='phone' onChange={e => this.handleChange(e)} value={this.state.phone}  />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label htmlFor="email">Email</Form.Label>
                                                    <Form.Control size="lg"  name="email" onChange={e => this.handleChange(e)} value={this.state.email} />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label htmlFor="userName">User Name</Form.Label>
                                                    <Form.Control size="lg" name="userName" onChange={e => this.handleChange(e)} value={this.state.userName} onBlur={() => this.handleBlur()} />
                                                    {(this.state.userNameInUse == true) &&
                                                        <Alert variant="danger" id="invalid">User Name is already in use</Alert>
                                                    }
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label htmlFor="password">Password</Form.Label>
                                                    <Form.Control size="lg" name="password" onChange={e => this.handleChange(e)} value={this.state.password} />
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            {(this.state.userNameInUse == false) &&
                                <Button className="rounded-pill btn-secondary" style={{color: '#000', backgroundColor: '#f1f8e9'}} onClick={this.handleSubmit}>Submit</Button>
                            }
                            {this.state.isEdit == true &&
                                <Button className="rounded-pill btn-danger" style={{ position: "absolute", right: 10 }} onClick={this.handleInactivate}>Inactivate</Button>
                            }
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        );
    }
}
export default Agency;