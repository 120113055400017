import React, { Component, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';


class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            firstName: '',
            lastName: '',
            phone: '',
            password: '',
            email: '',
            submitted: false,
            status: 1,
            statusName: '',
            userNameInUse: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }
    componentDidMount(){
        
    }
    componentDidUpdate(prevProps){
       
    }
    handleSubmit = async () => {
        const userStatus = localStorage.getItem('status');
        const agency = {
            agencyId: localStorage.getItem('agencyId'),
            userName: this.state.userName,
            password: this.state.password,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phone: this.state.phone, 
            email: this.state.email,
            status: userStatus == 3 && this.state.status == 1 ? 0 : this.state.status
        };
        try {
            this.setState({ loading: true }, async () => {
                const response = await  fetch('https://api.leadmanagertraining.com/api/ezllusers', { 
                    method: 'POST', 
                    body: JSON.stringify(agency),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const user = await response.json();
                if(user.id == 0){
                    this.setState({loading: false});
                    //this.setState({invalid: true});
                }
                else{
                    this.setState({
                        loading: false, 
                        submitted: true,
                        firstName: '',
                        lastName: '',
                        userName: '',
                        password: '',
                        phone: '',
                        email: '',
                    });
                }
            });
        }
        catch(e) {
            console.log('Submit Failed', e);
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleStatusChange = (e) => {
        console.log(e.target.value);
        var status = e.target.value == "Telemarketer" ? 0 : 3;
        this.setState({status: status});
        this.setState({statusName: e.target.value});
    }
    handleStateChange = (e) => {
        this.setState({
            state: e.target.value
        });
    }
    handleModalClose = () => {
        this.setState({show: false});
    }
    handleModalShow = () => {
        this.setState({show: true});
    }
    handleBlur = async () => {
        const userResponse = await fetch('https://api.leadmanagertraining.com/api/ezllusers', { 
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const users = await userResponse.json();
        const user = users.filter(u => u.userName == this.state.userName);
        console.log(user);
        const userNameInUse = user.length > 0 ? true : false;
        this.setState({userNameInUse: userNameInUse});
    }
    render() {  
        const stateList = require('../data/states.json');
        const status = localStorage.getItem('status');
        return (
            <div style={{marginLeft: 10, marginTop: 10, width: '60%'}}>
                <Card>
                    <Card.Header>REGISTER USER</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={10}>
                                <Form.Group>
                                    <Form.Label htmlFor="firstName">First Name</Form.Label>
                                    <Form.Control size="lg" name="firstName" onChange={e => this.handleChange(e)} value={this.state.firstName} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label htmlFor="lastName">Last Name</Form.Label>
                                    <Form.Control size="lg" name="lastName" onChange={e => this.handleChange(e)} value={this.state.lastName} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label htmlFor="phone">Phone</Form.Label>
                                    <Form.Control size="lg" name='phone' onChange={e => this.handleChange(e)} value={this.state.phone}  />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label htmlFor="email">Email</Form.Label>
                                    <Form.Control size="lg"  name="email" onChange={e => this.handleChange(e)} value={this.state.email} />
                                </Form.Group>
                                {status == 3 &&
                                    <Form.Group>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control as="select" onChange={(e) => this.handleStatusChange(e)} placeholder="Select User Status" value={this.state.statusName}>
                                            <option>Telemarketer</option>
                                            <option>Site Admin</option>
                                        </Form.Control>
                                    </Form.Group>
                                }
                                <div style={{paddingTop: 10}}>
                                    <Card border="success">
                                        <Card.Header>LOGIN INFORMATION</Card.Header>
                                        <Card.Body>
                                        <Form.Group>
                                            <Form.Label htmlFor="userName">User Name</Form.Label>
                                            <Form.Control size="lg" name="userName" onChange={e => this.handleChange(e)} value={this.state.userName} onBlur={() => this.handleBlur()} />
                                            {(this.state.userNameInUse == true) &&
                                                <Alert variant="danger" id="invalid">User Name is already in use</Alert>
                                            }
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label htmlFor="password">Password</Form.Label>
                                            <Form.Control size="lg" name="password" onChange={e => this.handleChange(e)} value={this.state.password} />
                                        </Form.Group>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        {(this.state.userNameInUse == false) &&
                            <Button className="rounded-pill btn-secondary" style={{color: '#000', backgroundColor: '#f1f8e9'}} onClick={this.handleSubmit} disabled={this.state.loading}> {this.state.loading == true ? 'Please Wait' : 'Submit'}</Button>
                        }
                        {this.state.submitted &&
                            <Alert key='success' variant='success'>
                                User submitted successfully!
                            </Alert>
                        }
                    </Card.Footer>
                </Card>
            </div>
        );
    }
}
export default Register;