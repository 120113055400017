import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';

import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';

const Testing = () => {
    const [ connection, setConnection ] = useState(null);
    const [ user, setUser ] = useState('');

    useEffect(async () => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('https://api.leadmanagertraining.com/hubs/chat', {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets 
            })
            .withAutomaticReconnect()
            .build();
            setConnection(newConnection);
    }, []);

    useEffect(() => {
            if (connection) {
                connection.start()
                .then(function () {
                    connection.on('RefetchChat', user => {
                        setUser(user);
                    });
                    connection.invoke('GetConnectionId')
                    .then(function (connectionId) {
                        console.log('Connected - ' + connectionId);
                            try {
                                var agencyId = localStorage.getItem('agencyId');
                                fetch('https://api.leadmanagertraining.com/api/chat/joingroup?agencyId=' + agencyId + '&connectionId=' + connectionId, { 
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                });
                            }
                            catch(e) {
                                console.log('Sending message failed.', e);
                            }
                    });

                })
                .catch(e => console.log('Connection failed: ', e));
            }
        
    }, [connection]);

    return (
        <div>
            <span>Testing:</span> 
            {
            user != '' &&
            <p>{user} just got the data</p>
        }
        </div>
    );
};

export default Testing;