import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { BsCalendarCheck } from 'react-icons/bs';

class TCPA extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        };
    }

    render() {
        return (
            <div className="container">
                <h3>EZ Marketing LLC, DBA EZ Live Leads: Compliance With TCPA Guidelines and Do Not Call Regulations Company Handbook</h3>
                <p><i>Table of Contents:</i></p>
                <ol type="1">
                    <li style={{paddingBottom: 5}}>Introduction</li>
                    <li style={{paddingBottom: 5}}>Purpose of the Handbook</li>
                    <li style={{paddingBottom: 5}}>Compliance with TCPA Guidelines and Do Not Call Regulations a. Understanding the TCPA Guidelines b. Understanding Do Not Call Regulations</li>
                    <li style={{paddingBottom: 5}}>Responsibilities of EZ Marketing LLC a. Training and Education b. Monitoring and Reporting c. Record-Keeping</li>
                    <li style={{paddingBottom: 5}}>Responsibilities of Employees a. Training and Education b. Conducting Calls c. Reporting Violations</li>
                    <li style={{paddingBottom: 5}}>Consequences of Non-Compliance</li>
                    <li style={{paddingBottom: 5}}>Reporting Violations</li>
                    <li style={{paddingBottom: 5}}>Revocation Of Consent Guidelines</li>
                    <li style={{paddingBottom: 5}}>Conclusion</li>
                </ol>
                <ol>
                    <li style={{fontWeight: 'bold'}}>Introduction</li>
                        <p>Welcome to EZ Marketing LLC! We are committed to providing excellent marketing services while adhering to ethical and legal standards. This Company Handbook outlines our commitment to complying with the Telephone Consumer Protection Act (TCPA) guidelines and Do Not Call regulations. All employees are required to familiarize themselves with and adhere to these guidelines.</p>
                    <li style={{fontWeight: 'bold'}}>Purpose of the Handbook</li>
                        <p>This handbook serves as a guide for EZ Marketing LLC and its employees to ensure compliance with TCPA guidelines and Do Not Call regulations. By following these guidelines, we maintain the highest standards of professionalism, ethics, and legality in our marketing efforts.</p>
                    <li style={{fontWeight: 'bold'}}>Compliance with TCPA Guidelines and Do Not Call Regulations</li>
                        <ol type="a">
                            <li style={{fontWeight: 'bold'}}>Understanding the TCPA Guidelines</li>
                                <p>The TCPA is a federal law that restricts telemarketing and requires consent from individuals before sending them unsolicited marketing communications via phone, fax, or text. EZ Marketing LLC is committed to complying with these guidelines, and all employees must understand and adhere to the following TCPA requirements:</p>
                                <ul>
                                    <li>Obtain prior written consent from individuals before sending marketing messages via automated phone calls, prerecorded messages, or text messages.</li>
                                    <li>Maintain a "Do Not Call" list to honor individuals' requests to opt-out of marketing communications.</li>
                                    <li>Do Not contact prospects before 9:00 a.m. or after 6:00 p.m. local time.</li>
                                    <li>Identify the caller and provide accurate contact information during each marketing call.</li>
                                    <li>Maintain records of consent, opt-out requests, and communications.</li>
                                </ul>
                            <li style={{fontWeight: 'bold'}}>Understanding Do Not Call Regulations</li>
                                <p>Do Not Call regulations, established by the Federal Trade Commission (FTC), prohibit contacting individuals who have registered their phone numbers on the National Do Not Call Registry. EZ Marketing LLC is committed to complying with these regulations, and all employees must understand and adhere to the following Do Not Call requirements:</p>
                                <ul>
                                    <li>Maintain and regularly update a list of phone numbers from the National Do Not Call Registry.</li>
                                    <li>Refrain from making marketing calls to numbers on the Do Not Call list.</li>
                                    <li>Honor individuals' requests to be added to the company-specific Do Not Call list.</li>
                                    <li>Train employees to identify and respect Do Not Call requests.</li>
                                </ul>
                        </ol>
                    <li style={{fontWeight: 'bold'}}>Responsibilities of EZ Marketing LLC</li>
                        <ol type="a">
                            <li style={{fontWeight: 'bold'}}>Training and Education</li>
                                <p>EZ Marketing LLC is responsible for providing comprehensive training and educational resources to all employees. This includes TCPA and Do Not Call regulation training, which should be conducted regularly to keep employees updated on compliance requirements.</p>
                            <li style={{fontWeight: 'bold'}}>Monitoring and Reporting</li>
                                <p>The company will regularly monitor marketing activities to ensure compliance with TCPA and Do Not Call regulations. Any violations or potential issues should be reported promptly to the compliance department.</p>
                            <li style={{fontWeight: 'bold'}}>Record-Keeping</li>
                                <p>EZ Marketing LLC will maintain accurate records related to TCPA consent, opt-out requests, and marketing communications. These records will be retained in accordance with applicable regulations.</p>
                        </ol>
                    <li style={{fontWeight: 'bold'}}>Responsibilities of Employees</li>
                        <ol type="a">
                            <li style={{fontWeight: 'bold'}}>Training and Education</li>
                                <p>All employees must complete mandatory training on TCPA guidelines and Do Not Call regulations. It is their responsibility to stay informed about any updates or changes to these regulations.</p>
                            <li style={{fontWeight: 'bold'}}>Conducting Calls</li>
                                <p>Employees are responsible for ensuring that all marketing calls and communications are made in compliance with TCPA and Do Not Call regulations. This includes obtaining proper consent, respecting opt-out requests, and following call time restrictions.</p>
                            <li style={{fontWeight: 'bold'}}>Reporting Violations</li>
                                <p>Employees who become aware of any violations or potential issues related to TCPA or Do Not Call compliance should promptly report them to their supervisor or the compliance department.</p>
                        </ol>
                    <li style={{fontWeight: 'bold'}}>Consequences of Non-Compliance</li>
                        <p>Non-compliance with TCPA and Do Not Call regulations can result in legal actions, fines, and damage to EZ Marketing LLC's reputation. Employees found in violation of these regulations may face disciplinary actions, including termination.</p>
                    <li style={{fontWeight: 'bold'}}>Reporting Violations</li>
                        <p>If you believe there has been a violation of TCPA guidelines or Do Not Call regulations, please report it immediately to the compliance department or through our internal reporting channels.</p>
                    <li style={{fontWeight: 'bold'}}>Revocation of Consent Guidelines</li>
                        <p>EZ Marketing LLC understands that individuals have the right to revoke their consent to receive marketing calls or messages at any time. We are committed to respecting these revocation requests promptly and efficiently. The following guidelines outline our procedures for handling the revocation of consent:</p>
                        <ol type="a">
                            <li style={{fontWeight: 'bold'}}>Clearly Communicated Revocation Process</li>
                                <p>EZ Marketing LLC will make it easy for individuals to revoke their consent. We will clearly communicate the process for revoking consent in all marketing communications, including providing contact information and clear instructions on how to opt-out.</p>
                            <li style={{fontWeight: 'bold'}}>Immediate Compliance</li>
                                <p>Upon receiving a revocation request, whether verbal or written, EZ Marketing LLC will promptly cease all marketing communications to the individual's phone number, email, or other contact methods specified in the request. This action will be taken within the shortest reasonable time frame, typically within 24 hours of receipt.</p>
                            <li style={{fontWeight: 'bold'}}>Documentation and Record-Keeping</li>
                                <p>EZ Marketing LLC will maintain detailed records of all revocation requests received, including the date, time, method, and content of the request. These records will be kept for a period in accordance with applicable regulations.</p>
                            <li style={{fontWeight: 'bold'}}>Training and Awareness</li>
                                <p>All employees will be trained to recognize and respond to revocation requests promptly and professionally. They will be educated on the importance of respecting these requests and the potential legal consequences of non-compliance.</p>
                            <li style={{fontWeight: 'bold'}}>Ongoing Compliance Monitoring</li>
                                <p>EZ Marketing LLC will implement ongoing compliance monitoring to ensure that all revocation requests are properly handled and that individuals who have revoked their consent are not contacted for marketing purposes.</p>
                            <li style={{fontWeight: 'bold'}}>Periodic Review and Updates</li>
                                <p>Our company will periodically review and update our procedures for handling revocation requests to ensure they remain compliant with TCPA guidelines and any other relevant regulations. Any necessary updates will be communicated to employees through training and internal communications.</p>
                            <li style={{fontWeight: 'bold'}}>Respect for Individual Choices</li>
                                <p>EZ Marketing LLC recognizes that respecting individuals' choices regarding marketing communications is essential for maintaining trust and compliance. We are committed to upholding these guidelines to honor individuals' revocation of consent requests promptly and professionally.</p>
                                <p>By following these guidelines, EZ Marketing LLC aims to ensure that the revocation of consent process is transparent, efficient, and compliant with all applicable regulations, thereby maintaining our commitment to ethical and responsible marketing practices.</p>
                        </ol>      
                    <li style={{fontWeight: 'bold'}}>Conclusion</li>
                        <p>EZ Marketing LLC is committed to conducting marketing activities in a responsible, ethical, and legal manner. Compliance with TCPA guidelines and Do Not Call regulations is essential to maintain our reputation and protect the interests of our clients and customers. By following the guidelines outlined in this Company Handbook, we ensure that our marketing efforts are conducted with the highest level of integrity and professionalism. Thank you for your dedication to compliance and excellence in marketing at EZ Marketing LLC.</p> 
                </ol>
            </div>
        );
    }
}
export default TCPA;