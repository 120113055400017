import React, { Component, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import moment from 'moment';

class Agency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chats: [],
            users: [],
            agencies: []
        };
        this.handleAgencyChange = this.handleAgencyChange.bind(this);
    }
    componentDidMount = async () => {
        var agencyId = localStorage.getItem('agencyId');
        const userId = localStorage.getItem('userId');
        if(userId == "" || !userId)
        {
            this.props.history.push('/home');
        }else{
            const chatResponse = await fetch('https://api.leadmanagertraining.com/api/chat/getautochatdata?agencyid=' + agencyId, { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const chats = await chatResponse.json();
            const agenctResp = await fetch('https://api.leadmanagertraining.com/api/ezllagency', { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const agencies = await agenctResp.json();
            this.setState({chats: chats, agencies: agencies});
        }
    }
    componentDidUpdate(prevProps){
       
    }
    handleAgencyChange = async (e) => {
        const chatResponse = await fetch('https://api.leadmanagertraining.com/api/chat/getautochatdata?agencyid=' + e.target.value, { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const chats = await chatResponse.json();
            this.setState({chats: chats});
    }
    render() {  
        
        return (
            <div style={{marginLeft: 10, marginTop: 10, width: '100%'}}>
                <Card>
                    <Card.Header>
                        CHATS
                    </Card.Header>
                    <Card.Body>
                        <Row style={{paddingTop: 10, paddingBottom: 10}}>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Choose Agency</Form.Label>
                                    <Form.Control  as="select" onChange={this.handleAgencyChange} className="form-select">
                                        <option value=''></option>
                                        {this.state.agencies.filter(i => i.inactive == false).map(a => 
                                            <option value={a.id}>{a.name}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Address</th>
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Zip</th>
                                            <th>Phone</th>
                                            <th>Email</th>
                                            <th>Notes</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Transferred To</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.chats.map(u => 
                                                <tr>
                                                    <td>
                                                        {u.firstName}
                                                    </td>
                                                    <td>
                                                        {u.lastName}
                                                    </td>
                                                    <td>
                                                        {u.address1}
                                                    </td>
                                                    <td>
                                                        {u.city}
                                                    </td>
                                                    <td>
                                                        {u.state}
                                                    </td>
                                                    <td>
                                                        {u.zip}
                                                    </td>
                                                    <td>
                                                        {u.primaryPhone}
                                                    </td>
                                                    <td>
                                                        {u.email}
                                                    </td>
                                                    <td>
                                                        {u.notes}
                                                    </td>
                                                    <td>
                                                        {moment(u.dts).format('MM/DD/YYYY h:mm a')}
                                                    </td>
                                                    <td>
                                                        {u.status}
                                                    </td>
                                                    <td>
                                                        {u.userDisplayName}
                                                    </td>
                                                    
                                                </tr>
                                            )
                                        }
                                        
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}
export default Agency;