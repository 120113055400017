import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Message from './Message';

const ChatWindow = (props) => {
    const [chats, setChats] = useState([]);
    const [autoChat, setAutoChat] = useState([]);

    useEffect(async () => {
        console.log(props.users);
        var agencyId = localStorage.getItem('agencyId');
        console.log(props.agencyId);
        const response = await fetch('https://api.leadmanagertraining.com/api/chat/' + props.agencyId, { 
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const chats = await response.json();
        setChats(chats);
    }, [props.agencyId])

    useEffect(() => {
        if (props.connection) {
            props.connection.start()
            .then(function () {
                props.connection.on('RefetchChat', user => {
                    fetch('https://api.leadmanagertraining.com/api/chat/' + props.agencyId, { 
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => response.json())
                    .then(response => {
                        setChats(response);
                    })
                    .catch(err => {
                        console.log(err);
                    });
                    // const chats = response.json();
                    // console.log(chats);
                    // setChats(chats);
                });
                props.connection.invoke('GetConnectionId')
                .then(function (connectionId) {
                    console.log('Connected - ' + connectionId);
                        try {
                            var agencyId = localStorage.getItem('agencyId');
                            fetch('https://api.leadmanagertraining.com/api/chat/joingroup?agencyId=' + agencyId + '&connectionId=' + connectionId, { 
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                        }
                        catch(e) {
                            console.log('Sending message failed.', e);
                        }
                });

            })
            .catch(e => console.log('Connection failed: ', e));
        }
}, [props.connection]);

    const chat = props.chat
        .map(m => <Message 
            key={Date.now() * Math.random()}
            user={m.user}
            message={m.message}
            dts={moment().format('MM/DD/YYYY h:mm a')}
            userId={m.userId} users={props.users} id={m.id} />);
    
    return(
        <div>
        <Row style={{marginLeft: 10}}>
            <Col md={6}>
                {chat}
            </Col>
        </Row>
        <Row style={{marginLeft: 10}}>
            <Col md={6}>
                {chats.map(m => 
                <Message 
                    key={Date.now() * Math.random()}
                    user={m.user}
                    message={m.message}
                    dts={moment(m.dts).add(3, 'hours')}
                    userId={m.userId} users={props.users} id={m.id} status={m.status} transferUserId={m.transferUserId} />)}
            </Col>
        </Row>
    </div>
    )
};

export default ChatWindow;