import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { BsCalendarCheck } from 'react-icons/bs';

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        };
    }

    render() {
        return (
            //<div style={{paddingTop: 60, width: '100%', marginLeft: 0, backgroundColor: '#A7F432'}}>
            <div className="container" style={{border: 'solid 10px black', paddingTop: 50}}>
                <p style={{fontSize: 29, paddingTop: 10, borderBottom: 'solid 10px #A7F432', borderRight: 'solid 10px #A7F432'}}>
                    <strong style={{fontSize: 45, backgroundColor: '#A7F432'}}> Our Mission </strong>at EZ Live Leads is to make Marketing EASY and TRANSPARENT for our clients.  We refuse to take advantage of your eagerness to grow.  We will take the time to speak with you one-on-one to find out if our marketing strategies are a good fit for your business.  We will go even further to provide the tools necessary to hold our marketing efforts accountable.  No other Live Lead Transfer or Mail Marketing company will provide as much transparency and accountability as we will.
                </p>
                <p style={{textAlign: 'center'}}>
                    <a className='btn btn-outline-secondary btn-lg rounded-pill' href='https://letsmeet.io/ezliveleads1/ez-live-initial-consultation' target="_blank" style={{color: '#000', backgroundColor: '#f1f8e9'}}>
                        <BsCalendarCheck /> SCHEDULE YOUR CONSULTATION
                    </a>
                </p>
                {/* <Jumbotron style={{textAlign: 'center', backgroundColor: '#fff'}}>
                    <p style={{fontSize: 16}}>Our Mission at EZ Live Leads is to make Marketing EASY and TRANSPARENT for our clients.  We refuse to take advantage of your eagerness to grow.  We will take the time to speak with you one-on-one to find out if our marketing strategies are a good fit for your business.  We will go even further to provide the tools necessary to hold our marketing efforts accountable.  No other Live Lead Transfer or Mail Marketing company will provide as much transparency and accountability as we will.</p>
                    <a className='btn btn-outline-secondary btn-lg' href='https://CALENDAR-APPOINTMENT.as.me/EZ-LIVE-LEADS' target="_blank" style={{color: '#000'}}>
                        <BsCalendarCheck /> SCHEDULE YOUR CONSULTATION
                    </a> 
                </Jumbotron> */}
            </div>
            // <div>
            //     <Row style={{textAlign: 'center', paddingTop: 20}}>
            //         <Col>
            //             <a className='btn btn-outline-secondary btn-lg rounded-pill' href='https://CALENDAR-APPOINTMENT.as.me/EZ-LIVE-LEADS' target="_blank" style={{color: '#000', backgroundColor: '#f1f8e9'}}>
            //                 <BsCalendarCheck /> SCHEDULE YOUR CONSULTATION
            //             </a>
            //         </Col>
            //     </Row>
            //     <Row id="page-container">
            //         <Col>
            //             <Image src="ezliveleadshome.jpg" style={{height: 600}} className="mx-auto d-block img-fluid shadow-4"></Image>
            //         </Col>
            //     </Row>
                
            //</div>
        );
    }
}
export default Home;