import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';


class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            lastName: '',
            userName: '',
            message: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        
    }
    componentDidUpdate(prevProps){
        
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = async () => {
        this.setState({loading: true});
        const request = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            message: this.state.message
        }
        try {
            await  fetch('https://api.leadmanagertraining.com/api/ezllusers/ContactEZLLAdmin', { 
                method: 'POST', 
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        }
        catch(e) {
            console.log('Submit Failed', e);
        }
        this.setState({loading: false});
        this.setState({firstName: '',lastName: '',email: '',message: '',})
    }
    render() {
        return (
            <div style={{marginLeft: 10, marginTop: 10, width: '60%'}}>
                <Row>
                    <Col>
                        <Card border="success">
                            <Card.Header>Contact Us</Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col sm={8}>
                                        <Form.Group style={{paddingBottom: 10}}>
                                            <Form.Control size="lg" placeholder="First Name" name="firstName" onChange={e => this.handleChange(e)} value={this.state.firstName} />
                                        </Form.Group>
                                        <Form.Group style={{paddingBottom: 10}}>
                                            <Form.Control size="lg" placeholder="Last Name" name="lastName" onChange={e => this.handleChange(e)} value={this.state.lastName} />
                                        </Form.Group>
                                        <Form.Group style={{paddingBottom: 10}}>
                                            <Form.Control size="lg" placeholder="Email" name="email" onChange={e => this.handleChange(e)} value={this.state.email} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control as="textarea" rows={3} size="lg" placeholder="Message" name="message" onChange={e => this.handleChange(e)} value={this.state.message} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                
                            </Card.Body>
                            <Card.Footer>
                                <Row>
                                    <Col>
                                        <Button variant="success" onClick={e => this.handleSubmit(e)} disabled={this.props.isLoading}> {this.props.isLoading == true ? 'Please Wait' : 'Submit'}</Button>
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: 10}}>
                                    <Col>
                                    {
                                        (this.props.contact == "Success!") &&
                                            <Alert variant="success" id="sent">Message has been sent to the Admins!</Alert>
                                    }
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default Contact;