import React, { useState, useEffect } from 'react';
import {useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ChatInput = (props) => {
    const [user, setUser] = useState('');
    const [message, setMessage] = useState('');
    const [agencies, setAgencies] = useState([]);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const history = useHistory();

    useEffect(async () => {
        try {
            var userId = localStorage.getItem('userId');
            const response = await fetch('https://api.leadmanagertraining.com/api/ezllusers/' + userId, { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const user = await response.json();
            if(user.id != 0){
                setUser(user.firstName + ' ' + user.lastName);
            }
        }
        catch(e) {
            console.log('Submit Failed', e);
        }
        const agencyResponse = await fetch('https://api.leadmanagertraining.com/api/ezllagency', { 
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const agencies = await agencyResponse.json();
        setAgencies(agencies);
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();

        const isUserProvided = user && user !== '';
        const isMessageProvided = message && message !== '';

        if (isMessageProvided) {
            props.sendMessage(user, message);
            setEditorState(EditorState.createEmpty());
            //setMessage('');
        } 
        else {
            alert('Please insert a message.');
        }
    }

    const onUserUpdate = (e) => {
        setUser(e.target.value);
    }

    const onMessageUpdate = (e) => {
        var message = draftToHtml(convertToRaw(e.getCurrentContent()))
        console.log(message);
        setMessage(message);
        setEditorState(e);
    }
    const handleAgencyChange = (e) => {
        props.onAgencyChange(e.target.value);
    }
    const status = localStorage.getItem('status')
    return (
        
        <div>
            {status == 3 &&
                <Row style={{paddingTop: 10, marginLeft: 30}}>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Choose Agency</Form.Label>
                            <Form.Control  as="select" onChange={handleAgencyChange} className="form-select">
                                <option value=''></option>
                            {agencies.filter(i => i.inactive == false).map(a => 
                                <option value={a.id}>{a.name}</option>
                            )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            }
            <Row style={{paddingTop: 10, marginLeft: 30}}>
                <Col md={6} >
                    <form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message</Form.Label>
                            <Editor wrapperStyle={{border: 'solid 1px #ccc'}} editorStyle={{height: 150}} onEditorStateChange={onMessageUpdate} editorState={editorState} />
                            {/* <Form.Control as="textarea" rows={3} value={message} onChange={onMessageUpdate} /> */}
                        </Form.Group>
                        <Button className="rounded-pill btn-secondary" style={{color: '#000', backgroundColor: '#f1f8e9'}} onClick={onSubmit}>Submit</Button>
                        <Button className="rounded-pill btn-secondary float-end" style={{color: '#000', backgroundColor: '#f1f8e9'}} onClick={() => history.push('contact')}>Contact Admin</Button>
                    </form>
                </Col>
            </Row>
        </div>
    )
};

export default ChatInput;